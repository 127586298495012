<template>
  <div>
    <Loading v-if="isLoading" />
    <v-card tile elevation="0" flat v-show="!isLoading">
      <v-card-text>
        {{ $t("noShow.txtTitle") }}

        <v-alert border="left" colored-border type="info" tile>
          <ul class="infoPhotos">
            <li>{{ $t("global.components.noshow.txtPhoto1") }}</li>
            <li>{{ $t("global.components.noshow.txtPhoto2") }}</li>
            <li>{{ $t("global.components.noshow.txtPhoto3") }}</li>
          </ul>
        </v-alert>

        <v-btn
          block
          tile
          depressed
          class="mt-4"
          color="#E5E3FF"
          @click.prevent="showCamera"
        >
          <v-icon left> mdi-camera </v-icon>{{ $t("noShow.takePhoto") }}</v-btn
        >
      </v-card-text>

      <v-form ref="form" v-model="form" class="pa-4">
        <v-row>
          <v-col
            v-for="(n, index) in photos"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <div>
              <v-img :src="n" aspect-ratio="1" class="deep-purple">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="deep-purple"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-btn
                color="error"
                block
                tile
                x-small
                dark
                depressed
                @click.prevent="removeImage(index)"
              >
                {{ $t("global.txtDelete") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-textarea
          v-model="notes"
          auto-grow
          color="deep-purple"
          :label="$t('booking.notes')"
          class="mt-6"
          filled
        ></v-textarea>
      </v-form>

      <div class="pl-4 pr-4 bposition">
        <v-row>
          <!-- <v-col cols="6">
						<v-btn
							depressed
							tile
							@click="$refs.form.reset()"
							block
							color="warning"
						>
							Cancelar
						</v-btn>
					</v-col> -->
          <v-col cols="12">
            <v-btn
              depressed
              tile
              block
              :disabled="!validNoShow || sendNoShow"
              color="deep-purple accent-4"
              :dark="validNoShow"
              @click.prevent="noshow"
            >
              No Show
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <camera
      v-model="openCamera"
      :title="$t('noShow.takePhoto')"
      @capture="capture"
    />

    <v-dialog
      v-model="dialogLoading"
      persistent
      width="200"
      dark
      class="loadingBlock"
    >
      <v-card color="deep-purple accent-4" dark>
        <v-card-text class="pt10">
          <span class="text-white">{{ $t("home.waiting") }}...</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const Loading = () =>
  import(/* webpackChunkName: "Loading" */ "@/components/loading.vue");
const camera = () =>
  import(/* webpackChunkName: "Camera" */ "@/components/camera.vue");

export default {
  name: "NoShow",
  components: { Loading, camera },
  data() {
    return {
      isLoading: true,
      position: null,
      booking: {},
      form: false,
      notes: "",
      agreement: false,
      openCamera: false,
      photos: [],
      sendNoShow: false,
      modelReference: "transport-services",
      rules: {
        required: (v) => !!v || "This field is required",
      },
    };
  },
  methods: {
    ...mapActions(['initGpsTracking']),
    ...mapActions("ui", {
      disabledNavigation: "disabledNavigation",
      enableddNavigation: "enableddNavigation",
      openQRReaderOnlyEvent: "openQRReaderOnlyEvent",
      activeBackBtn: "activeBackBtn",
      showProgress: "showProgress",
      hideProgress: "hideProgress",
      resetQR: "resetQR",
    }),
    capture(photo) {
      this.photos.push(photo);
    },
    removeImage(index) {
      this.photos.splice(index, 1);
    },
    showCamera() {
      this.openCamera = true;
    },
    fetchModel() {
      this.$axios
        .get(`driver/transport-services/${this.$route.params.id}`)
        .then((res) => {
          this.booking = res.data;
          this.isLoading = false;
          this.initMap();
        })
        .catch(() => {
          //
        });
    },
    async getLocation() {
      await this.initGpsTracking();
      return this.geolocationData;
    },
    async saveGpcLocationStatus(status) {
      const position = await this.getLocation();
      // Guardar en store
      this.addGpcLocation({
        status,
        latitude: position.latitude,
        longitude: position.longitude,
        speed: position.speed,
      });
    },
    async initMap() {
      const position = await this.getLocation();
      this.position = {
        lat: position.latitude,
        lng: position.longitude,
        speed: position.speed,
      };
    },
    async noshow() {
      this.showProgress();

      this.sendNoShow = true;

      const f = this.convertFiles(this.photos);
      // Recuperar la posicion actual
      const position = await this.getLocation();

      // Subir todos los archivos
      for (let i = 0; i < f.length; i++) {
        await this.uploadImage({
          input: f[i],
          key: "changeStatusEvidence[]",
        });
      }

      // Almacenar el evento
      this.saveGpcLocationStatus("noShow");

      const url = `driver/transport-services/${this.booking.id}/change-tracking-status`;

      this.$axios
        .put(url, {
          status: "noShow",
          tracking: {
            latitude: position.latitude,
            longitude: position.longitude,
            velocityKmh: position.speed || 0,
          },
        })
        .then(async () => {
          this.hideProgress();
          // Retornar a los servicios
          this.$router.push({ name: "Home" });
        });
    },
    convertFiles(photos) {
      const files = [];
      photos.forEach((elm) => {
        var block = elm.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        files.push(this.b64toBlob(realData, contentType));
      });
      // Regresar todos los archivos
      return files;
    },
    uploadImage(fileState) {
      return new Promise((rr, ff) => {
        let formData = new FormData();
        formData.append("file", fileState.input);
        formData.append("keyName", fileState.key);

        this.$axios
          .post(`files/${this.modelReference}/${this.booking.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(() => {
            rr();
          })
          .catch(() => {
            ff();
          });
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  computed: {
    ...mapState(['geolocationData']),
    ...mapState("ui", {
      dialogLoading: (state) => state.core.dialogLoading,
    }),
    positionValid() {
      return this.position !== null;
    },
    validNoShow() {
      return this.photos.length >= 3;
    },
  },
  mounted() {
    this.activeBackBtn();
    this.disabledNavigation();
    this.fetchModel();
  },
};
</script>

<style>
.bposition {
  position: fixed;
  bottom: 20px;
  width: 100%;
}
.infoPhotos > li {
  margin-bottom: 7px;
  font-size: 13px;
  text-align: justify;
}
</style>
